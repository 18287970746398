.settingsWarpDiv{
    padding-top: 40px;
    padding-right: 20px;
    position: sticky;
    top: 65px;
}

.settingsList {
    gap: 5px;
    align-items: flex-start;
}

.Radios {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 0;
}


.Radios h2 {
    font-size: 1em;
    font-weight: 600;
    margin-bottom: 10px;
}

.RadioField {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    
}

.RadioField input {
    width: min-content;
    /* padding: 0 5 ; */
}


.validateInput{
    cursor: pointer;
}
.validateInput label {
    margin-bottom: 0;
}

.Radios li {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-start;
}

.Radios h2 {
    margin-bottom: 0;

}

.Radios label {
    margin-bottom: 0;

}